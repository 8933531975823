<template>
  <div class="h-item">
    <div class="help-headimg" v-if="hitem.helpUid"><img :src="hitem.helpHeadimgurl" :title="hitem.helpNickname"></div>
    <div class="headimg"><img :src="hitem.headimgurl" :title="hitem.nickname"></div>
    <div class="title">
      <router-link target="_blank" :to="{name: 'helpdetail', params: {id: hitem.id}}">{{hitem.title}}</router-link>
      <img class="copy-icon" src="../../assets/imgs/copy.png" title="复制标题" @click.stop="onCopyTitle">
      <img class="copy-icon" src="../../assets/imgs/copylink.png" title="复制标题和链接" @click.stop="onCopyTitleLink">
    </div>
    <div class="pmid">{{hitem.pmid}}</div>
    <div :class="['status', hitem.status]" :title="hitem.status==='failed'?hitem.failedDesc:''">{{mapStatus(hitem.status)}}</div>
    <div class="time">{{hitem.newTime}}</div>
    <div class="action">
      <!-- 限制升级，求助者和应助者可以看到 -->
      <!-- <div class="upload file" v-if="hitem.fileId && (hitem.status==='upload' || (hitem.status==='success' && unionid===hitem.unionid))"> -->
      <div class="upload file" v-if="hitem.fileId && (unionid===hitem.unionid || unionid===hitem.helpUid)">
        <router-link v-if="hitem.pmid" target="_blank" class="pdf-link" :to="{name: 'helppdf', params: {id: hitem.pmid}, query: {id: hitem.id}}">&nbsp;</router-link>
        <router-link v-else target="_blank" class="pdf-link" :to="{name: 'helppdf2', params: {id: hitem.fileId}, query: {id: hitem.id}}">&nbsp;</router-link>
        <!-- <a v-if="hitem.pmid" target="_blank" class="pdf-link" :href="`${httpRoot}/fulltext/${hitem.pmid}`">&nbsp;</a>
        <a v-else target="_blank" class="pdf-link" :href="`${httpRoot}/file/${hitem.fileId}`">&nbsp;</a> -->
        <span class="btn" v-if="unionid===hitem.unionid && hitem.status === 'upload'" @click="isOk">确认</span>
      </div>
      <div class="help" v-if="unionid!==hitem.unionid && (hitem.status==='new' || hitem.status==='failed')" @click="handleHelping">应助</div>
      <div class="delete" v-if="unionid===hitem.unionid && hitem.status==='new'" @click="handleDelete">删除</div>
      <div class="renew" v-if="unionid===hitem.unionid && hitem.status==='helping'" @click="handleRenew">重新求助</div>
      <div class="help upload" v-if="unionid===hitem.helpUid && (hitem.status==='helping' || hitem.status==='upload')">
        <span class="label" v-if="hitem.status==='helping'">上传</span>
        <span class="label" v-else-if="hitem.status==='upload'">再次上传</span>
        <input class="change" ref="file" type="file" @change="onFileChange" @click="loginValid"/>
        <span class="upload" :class="[uploadStatus]">{{uploadStatus==='success'?'文件上传成功':uploadStatus==='fail'?uploadErr:''}}</span>
      </div>
    </div>
    <help-confirm-win :show="showConfirmWin" @on-hide="showConfirmWin=false" @on-ok="onSuccess" @on-cancel="onFailed"></help-confirm-win>
    <help-delete-win :show="showDeleteWin" @on-hide="showDeleteWin=false" @on-ok="onDelete">确认删除该求助？</help-delete-win>
  </div>
</template>
<script>
import HelpConfirmWin from './help-confirm-win'
import HelpDeleteWin from './help-delete-win'
import Mixin from './mixin'
import { authenticate, catchExpired } from '../../utils/auth'
import { updateStorageItem } from '../../utils/cache'
export default {
  components: {HelpConfirmWin, HelpDeleteWin},
  mixins: [Mixin],
  props: {
    hitem: {
      type: Object,
      default () { return {} }
    },
    unionid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      id: this.hitem.id,
      showDeleteWin: false,
      uploadStatus: '',
      uploadErr: '',
      showConfirmWin: false
    }
  },
  methods: {
    loginValid (e) {
      if (!this.$parent.loggedIn) {
        e.preventDefault()
        updateStorageItem('route', {name: 'fullhelp'})
        this.$parent.showLoginTipWin = true
        return false
      } else {
        return true
      }
    },
    handleDelete () {
      this.showDeleteWin = true
    },
    onDelete () {
      this.deletePF()
    },
    isOk () {
      this.showConfirmWin = true
    },
    deletePF () {
      this.$http.post(`${this.httpRoot}/paper/fulltext/delete`, {id: this.hitem.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$emit('on-item-delete')
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleRenew () {
      this.$http.post(`${this.httpRoot}/paper/fulltext/renew`, {id: this.hitem.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$emit('on-item-renew')
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleHelping (e) {
      if (!this.loginValid(e)) return
      this.$http.post(`${this.httpRoot}/paper/fulltext/helping`, {id: this.hitem.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$emit('on-item-helping')
        } else {
          this.$parent.fulltextAuthFailed(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onCopyTitle () {
      let input = document.createElement('input')
      input.setAttribute('value', this.hitem.title)
      input.style.position = 'absolute'
      input.style.left = '-9999px'
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      this.$parent.showCopyTitleSuccess()
      setTimeout(() => {
        document.body.removeChild(input)
      }, 1000)
    },
    onCopyTitleLink () {
      let input = document.createElement('input')
      input.setAttribute('value', '应助文献领取奖品：\n' + this.hitem.title + ' ' + document.location.href.replace('fullhelp', 'helpdetail') + '/' + this.hitem.id)
      input.style.position = 'absolute'
      input.style.left = '-9999px'
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      this.$parent.showCopyTitleLinkSuccess()
      setTimeout(() => {
        document.body.removeChild(input)
      }, 1000)
    },
    onSuccess () {
      this.helpSuccess()
    },
    onFailed (desc) {
      this.helpFailed(desc)
    },
    helpSuccess () {
      this.$http.post(`${this.httpRoot}/paper/fulltext/success`, {id: this.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$emit('on-help-confirm')
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    helpFailed (desc) {
      this.$http.post(`${this.httpRoot}/paper/fulltext/failed`, {id: this.id, failedDesc: desc}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$emit('on-help-confirm')
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>
<style lang="less">
.h-item {
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 13px;
  display: flex;
  .help-headimg {
    position: absolute;
    top: 3px;
    left: -58px;
    img {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .headimg {
    width: 3%;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 19px;
      height: 19px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .title {
    width: 62%;
    color: #3f5d94;
    a {
      color: #3f5d94;
    }
    .copy-icon {
      width: 13px;
      height: 13px;
      cursor: pointer;
    }
  }
  .pmid {
    width: 7%;
    word-break: break-all;
    word-wrap: break-word;
    color: #666;
  }
  .status {
    width: 6%;
    &.new {
      color: #d82424;
    }
    &.success {
      color: #2e8540;
    }
    &.failed {
      color: #981a1a;
    }
  }
  .time {
    width: 14%;
  }
  .action {
    width: 8%;
    display: flex;
    overflow: hidden;
    .help, .delete, .renew {
      color: #1e75cc;
      cursor: pointer;
      &:hover {
        color: #3e09a2;
      }
    }
    .upload {
      position: relative;
      .label {
        cursor: pointer;
      }
      .change {
        position: absolute;
        overflow: hidden;
        left: 0;
        top: 0;
        opacity: 0;
      }
      .success {
      color: green;
      }
      .fail {
        color: red;
      }
      .pdf-link {
        display: inline-block;
        width: 1.5rem;
        height: 1.2rem;
        text-align: center;
        background: url(../../assets/imgs/pdf.png) no-repeat center/contain;
      }
      .btn {
        padding: 2px 5px;
        margin: 0 5px;
        background-color: #7ba0e4;
        color: #fff;
        cursor: pointer;
        &:hover {
          color: #ffe8e8;
        }
      }
    }
  }
}
</style>
