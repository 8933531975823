import ajax from '../common/upload/ajax'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  methods: {
    mapStatus (status) {
      return status === 'new'
              ? '求助'
              : status === 'helping'
                ? '应助中'
                : status === 'upload'
                  ? '已上传'
                  : status === 'success'
                    ? '应助成功'
                    : status === 'failed'
                      ? '应助失败'
                      : ''
    },
    formatTime (t) {
      return t ? new Date(t).format('yyyy-MM-dd hh:mm:ss') : ''
    },
    showLoadingUpload () {
      this.showLoadingCircle = true
      this.$parent.showLoadingCircle = true
    },
    hideLoadingUpload () {
      this.showLoadingCircle = false
      this.$parent.showLoadingCircle = false
    },
    onFileChange (e) {
      const files = e.target.files
      if (!files) {
        return
      }
      this.uploadFiles(files)
    },
    uploadFiles (files) {
      let postFiles = Array.prototype.slice.call(files)
      if (postFiles.length === 0) return
      postFiles.forEach(file => {
        this.upload(file)
      })
    },
    upload (file) {
      const fileFormat = file.name.split('.').pop().toLocaleLowerCase()
      if (fileFormat !== 'pdf') {
        this.uploadStatus = 'fail'
        this.uploadErr = '文件格式错误'
        return
      }
      this.showLoadingUpload()
      ajax({
        headers: authenticate().headers,
        withCredentials: false,
        file: file,
        data: {pmid: this.pmid ? this.pmid : this.hitem.pmid, doi: this.doi ? this.doi : this.hitem.doi},
        filename: 'file',
        action: `${this.httpRoot}/file/upload`,
        onProgress: e => {
          this.handleProgress(e, file)
        },
        onSuccess: res => {
          this.handleSuccess(res, file)
        },
        onError: (err, response) => {
          this.handleError(err, response, file)
        }
      })
    },
    handleProgress (e, file) {},
    handleSuccess (res, file) {
      if (res.status === 'success') {
        this.uploadStatus = 'success'
        this.uploadErr = ''
        this.fileId = res.id
        this.fulltextUpload(res.id)
      } else {
        this.uploadStatus = 'fail'
        this.uploadErr = res.statusText
        this.fileId = ''
        this.$refs.file.value = null
      }
      this.hideLoadingUpload()
    },
    handleError (err, res, file) {
      this.uploadStatus = 'fail'
      this.uploadErr = '文件上传出错'
      this.fileId = ''
      this.$refs.file.value = null
      console.log(err)
      this.hideLoadingUpload()
    },
    fulltextUpload (fileId) {
      this.$http.post(`${this.httpRoot}/paper/fulltext/updatefile`, {id: this.id, fileId}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.$emit('on-file-upload')
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
