<template>
  <modal v-model="showModal" dimmer maskClose :hideFooter="false" @on-ok="onOk" @on-cancel="onCancel">
    <div class="delete-tip">
      <slot></slot>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show,
      groupName: ''
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    onOk () {
      this.$emit('on-ok')
      this.showModal = false
    },
    onCancel () {
      this.showModal = false
    }
  }
}
</script>
<style lang="less">
.delete-tip {
  padding: 30px 50px 10px 50px;
}
</style>
