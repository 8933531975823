<template>
  <modal v-model="showModal" dimmer maskClose :hideFooter="false" okText="成功" cancelText="失败" @on-ok="onOk" @on-cancel="onCancel">
    <div class="delete-tip">
      <span style="height:40px;line-height:40px;color:#2a6aca;font-size:15px;">确认应助上传的文件是否成功？</span>
      <div>
        <span class="require">失败描述：</span>
        <input type="text" v-model="failedDesc">
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show,
      failedDesc: ''
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    onOk () {
      this.$emit('on-ok')
      this.showModal = false
    },
    onCancel () {
      let str = this.failedDesc || ''
      str = str.replace(/\s/g, '')
      if (str && str.length > 1) {
        this.$emit('on-cancel', str)
        this.showModal = false
      } else {
        this.$parent.$parent.needFailedDesc()
      }
    }
  }
}
</script>
<style lang="less">
.delete-tip {
  padding: 30px 50px 10px 50px;
  .require {
    position: relative;
    &::before {
      content: "*";
      color: red;
      position: absolute;
      display: block;
      top: 0;
      left: -5px;
    }
  }
}
</style>
